.sd-navbar-custom {
  height: 125px;
  font-family: Barlow;
  // position: sticky;
  // top: 0px;
  // z-index: 3000;

  .sd-top-nav {
    height: 35px;
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    background-color: #2f3193;
    justify-content: space-between;
    .sd-top-nav-left {
      color: white;
      display: flex;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      div {
        font-size: 15px;
        padding-left: 14px;
        display: flex;
        img {
          height: 26px;
          width: 26px;
          padding: 5px;
          cursor: pointer;
        }
      }
    }
    .sd-top-nav-right {
      display: flex;
      width: 42%;
      height: 100%;
      justify-content: flex-end;
      .sd-inner-div {
        border-right: 1px solid #44469f;
        border-left: 1px solid #44469f;
        font-size: 11px;
        font-weight: 600;
        color: white;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        letter-spacing: 0.5px;
        cursor: pointer;
        div {
          display: flex;
          align-items: center;
        }
        &:hover {
          background-color: #222268;
        }
        img {
          margin-right: 6px;
          height: 14px;
          cursor: pointer;
        }
        a {
          text-decoration: none;
        }
        .sd-dropdown-signin {
          position: relative;
          width: 100%;
          display: inline-block;
          text-align: center;
          &:hover .sd-dropdown-content-signin {
            display: flex;
          }
          &:hover {
            background-color: #222268;
          }
          img {
            padding-left: 10px;
          }
          .sd-dropbtn-signin {
            text-transform: capitalize;
            width: 95px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            height: 100%;
            font-size: 11px;
            letter-spacing: 0.5px;
            font-weight: 600;
            color: white;
            outline: none;
            cursor: pointer;
            border: none;
            box-shadow: none;
            border-radius: 0px;
            background-color: transparent;
            .spcl {
              height: 16px;
              margin: 0px;
              padding-left: 0px;
              margin-right: 5px;
            }
            img {
              height: 7px;
              margin-left: 6px;
              margin-top: 2px;
            }
          }
          .sd-dropdown-content-signin {
            display: none;
            flex-direction: column;
            position: absolute;
            background-color: white;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1000;
            margin-top: 0.1vh;
            // margin-left: -64px;
            right: 0;
            text-align: left;
            color: black !important;
            border-radius: 3px;
            padding: 0.5vw 0px;
            a {
              width: 100%;
              font-size: 13px;
              padding: 5px 15px;
              color: #181818;
              text-decoration: none;
              font-weight: 500;
              cursor: pointer;
              &:hover {
                background-color: rgb(240, 239, 239);
              }
            }
          }
        }
      }
      .sd-w-22 {
        width: 22%;
      }
      .sd-w-18 {
        width: 18%;
      }
      .sd-w-17 {
        width: 17%;
      }
      .sd-w-24 {
        width: 24%;
      }
      .sd-w-25 {
        width: 25%;
      }

      .dropdown {
        width: 18%;
        &:hover {
          background-color: #222268;
        }
        button {
          width: 100%;
          white-space: nowrap;

          align-items: center;
          display: flex;
          justify-content: center;
          height: 100%;
          font-size: 11px;
          letter-spacing: 0.5px;
          font-weight: 600;
          color: white;
          outline: none;
          cursor: pointer;
          border-right: 1px solid #44469f;
          border-left: 1px solid #44469f;
          box-shadow: none;
          border-radius: 0px;
          img {
            height: 7px;
            margin-left: 6px;
            margin-top: 2px;
          }
        }
        .dropdown-menu {
          width: 100%;
          margin-left: -5%;
          a {
            font-size: 12px;
            padding: 7px 15px;
            color: #181818 !important;
            // color: black !important;
            &:hover {
              background-color: rgba(240, 239, 239, 0.774);
            }
          }
        }
      }
      .sd-dropdown-language {
        position: relative;
        width: 18%;
        display: inline-block;
        &:hover .sd-dropdown-content-language {
          display: flex;
        }
        &:hover {
          background-color: #222268;
        }
        .sd-dropbtn-language {
          width: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          height: 100%;
          font-size: 11px;
          letter-spacing: 0.5px;
          font-weight: 600;
          color: white;
          outline: none;
          cursor: pointer;
          // border-right: 1px solid #44469f;
          // border-left: 1px solid #44469f;
          border: none;
          box-shadow: none;
          border-radius: 0px;
          background-color: transparent;
          img {
            height: 7px;
            margin-left: 6px;
            margin-top: 2px;
          }
        }
        .sd-dropdown-content-language {
          display: none;
          flex-direction: column;
          position: absolute;
          background-color: white;
          min-width: 140px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1000;
          margin-top: 0.1vh;
          margin-left: 0vw;
          color: black !important;
          border-radius: 3px;
          padding: 4px 0px;
          a {
            // font-size: 13px;
            // padding: 6px 13px;
            // color: #181818;
            // text-decoration: none;
            // font-weight: 400;
            // cursor: pointer;

            width: 100%;
            font-size: 13px;
            padding: 4px 10px;
            color: #181818;
            text-decoration: none;
            font-weight: 400;
            cursor: pointer;
            &:hover {
              background-color: rgb(240, 239, 239);
            }
          }
        }
      }
      .sd-selected {
        background-color: #222268 !important;
      }
    }
  }

  .sd-bottom-nav {
    height: 90px;
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    background-color: #f58220;

    .sd-inner-navbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sd-hamburger-icon {
        display: none;

        div {
          width: 30px;
          height: 3px;
          background-color: white;
          margin: 6px 0;
        }
      }
      .sd-user-slider {
        display: none;
      }
      .sd-left-sider {
        display: none;
      }
      .sd-right-sider {
        display: none;
      }
      .sd-inner-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          height: 72px;
          cursor: pointer;
        }
        a {
          font-size: 19px;
          line-height: 19px;
          font-weight: 600;
          margin-left: 10px;
          letter-spacing: 0.2px;
          text-decoration: none;
          cursor: pointer;
          color: white;
        }
      }
      .sd-inner-right {
        display: flex;
        flex-direction: column;
        padding: 0px;
        .sd-top-list {
          padding: 5px;
          padding-right: 0px;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 5px;
          list-style: none;
          .item {
            a {
              font-weight: 400;
              color: white;
              font-size: 15px;
              text-decoration: none;
              padding: 2px 15px 4px;
              img {
                height: 18px;
              }
              &:hover {
                background-color: #de6b08;
              }
            }
            img {
              height: 18px;
              padding: 0px 15px;
              // padding: 2px 10px 4px;
            }
          }
          .sd-spcl {
            img {
              padding-right: 0px;
            }
          }
        }
        .sd-bottom-list {
          padding: 0px 0px 5px 5px;
          display: flex;
          margin-bottom: 0px;
          list-style: none;
          align-items: center;

          .item {
            align-self: center;
            a {
              font-weight: 600;
              color: white;
              padding: 2px 15px 4px;
              letter-spacing: 0.2px;
              font-size: 15px;
              text-decoration: none;
            }
            &:hover {
              background-color: #de6b08;
            }
          }
          .border-r {
            border-right: 1px solid #c4681a;
            align-self: center;
          }
          .sd-first-button {
            align-self: center;
            height: 100%;
            font-size: 15px;
            display: flex;
            white-space: nowrap;

            .dropdown-menu {
              display: none;
              width: 200px;
              margin-top: -0.1vh;
              color: black !important;

              a {
                font-size: 13px;
                padding: 6px 13px;
                color: #181818;
                text-decoration: none;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                  background-color: rgb(240, 239, 239);
                }
                &:hover > .side-hover {
                  display: block;
                }

                .side-hover {
                  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.2);
                  display: none;
                  padding-top: 1vh;
                  padding-bottom: 1vh;

                  margin-left: 82%;
                  background-color: white;
                  position: absolute;
                  min-width: 100%;
                  margin-top: -17%;
                  div {
                    font-size: 13px;
                    line-height: 16px;
                    padding: 8px 15px;
                    color: #181818;
                    &:hover {
                      background-color: rgb(240, 239, 239);
                    }
                    a {
                      color: black !important;
                      padding-left: 0vw;
                      text-decoration: none;
                      &:hover {
                        background-color: rgb(240, 239, 239);
                      }
                    }
                  }
                }
                .sd-pra {
                  max-width: 270px;
                  width: 270px;
                }
                .sd-par {
                  // max-width: 200px;
                  // width: 280px;
                }
                .sd-left-hover {
                  margin-right: 0%;
                  margin-left: -105%;
                }
              }
            }
            .sd-media {
              max-width: 140px;
            }

            .sd-left {
              margin-left: -85px !important;
              width: 170px !important;
            }

            &:hover {
              background-color: #de6b08;
            }
            &:hover > .dropdown-menu {
              display: flex !important;
              flex-direction: column;
            }
            .active {
              background-color: #de6c08b6;
            }
            button {
              height: 80%;
              font-size: 15px;
              background-color: #f58220;
              color: white;
              border: none;
              box-shadow: none;
              white-space: nowrap;

              font-weight: 600;
              margin: 0px;
              padding: 2px 15px 4px;
              letter-spacing: 0.2px;
              &:hover {
                background-color: #de6b08;
              }
            }
          }

          .sd-dropdown {
            position: relative;
            display: inline-block;

            &:hover .sd-dropdown-content {
              display: flex;
              flex-direction: column;
            }
            &:hover {
              background-color: #de6b08;
            }
            .sd-dropbtn {
              height: 80%;
              font-size: 15px;
              background-color: #f58220;
              color: white;
              border: none;
              box-shadow: none;
              font-weight: 600;
              margin: 0px;
              padding: 2px 15px 4px;
              letter-spacing: 0.2px;
              &:hover {
                background-color: #de6b08;
              }
            }
            .sd-dropdown-content {
              display: none;
              position: absolute;
              background-color: white;
              min-width: 160px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 1000;
              margin-top: -0.1vh;
              color: black !important;
              border-radius: 3px;
              padding: 0.5vw 0px;

              a {
                font-size: 13px;
                padding: 7px 15px;
                color: #181818;
                text-decoration: none;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                  background-color: rgb(240, 239, 239);
                }
              }
            }
            .active {
              background-color: #de6c08b6;
            }
          }

          .active {
            background-color: #de6c08b6;
          }
        }
      }
    }
  }
  .active-r {
    height: 50px;
    background-color: white;
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 42px -3px grey;
    position: absolute;
    z-index: 100000;
    border-radius: 50%;
    padding: 15px;
    margin: 80px 30px;
    bottom: 0 !important;
    right: 0 !important;
    transform: display 1s;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 13px;
      transform: rotate(180deg);
      filter: invert(1);
    }
  }
  .hide-r {
    display: none;
  }
}

@media only screen and (min-width : 1800px) {
  .sd-navbar-custom {
    .sd-top-nav {
      .sd-top-nav-right {
        .sd-inner-div {
          .sd-dropdown-signin {
            .sd-dropdown-content-signin {
              margin-left: -70px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width : 1600px) {
  .sd-navbar-custom {
    .sd-top-nav {
      .sd-top-nav-right {
        .sd-inner-div {
          .sd-dropdown-signin {
            .sd-dropdown-content-signin {
              // margin-left: -77.5px;
              min-width: 100%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-navbar-custom {
    height: 100px;
    .sd-top-nav {
      height: 25px;
      .sd-top-nav-left {
        font-size: 12px;

        div {
          font-size: 12px;
          padding-left: 8px;
          display: flex;
          img {
            height: 24px;
            width: 24px;
            padding: 5px;
          }
        }
      }
      .sd-top-nav-right {
        width: 45%;
        .sd-inner-div {
          font-size: 8px;

          img {
            margin-right: 6px;
            height: 10px;
            cursor: pointer;
          }
          a {
            text-decoration: none;
          }

          .sd-dropdown-signin {
            img {
              padding-left: 5px;
              margin-right: 0px;
            }
            .sd-dropbtn-signin {
              width: 85px;

              font-size: 8px;
              .spcl {
                padding-left: 0px;
                height: 11px;
              }
            }
            .sd-dropdown-content-signin {
              // margin-left: -75px;
              min-width: 11vw;
              a {
                font-size: 10px;
                padding: 2px 15px;
              }
            }
          }
        }

        .dropdown {
          width: 18%;
          &:hover {
            background-color: #222268;
          }
          button {
            white-space: nowrap;

            font-size: 8px;
            img {
              height: 6px;
              margin-left: 6px;
              margin-top: 2px;
            }
          }
          .dropdown-menu {
            width: 150px;
            margin-left: -5%;
            a {
              font-size: 11px;
              padding: 10px;
              padding-left: 30px;
              color: black !important;
            }
          }
        }
        .sd-dropdown-language {
          .sd-dropbtn-language {
            font-size: 8px;
            img {
              height: 5px;
              margin-left: 5px;
            }
          }
          .sd-dropdown-content-language {
            a {
              font-size: 10px;
              padding: 10px;
              padding-left: 30px;
              color: black !important;
            }
          }
        }

        .sd-selected {
          background-color: #222268 !important;
        }
      }
    }
    .sd-bottom-nav {
      height: 75px;
      .sd-inner-navbar {
        .sd-inner-left {
          .logo {
            height: 60px;
          }
          a {
            font-size: 15px;
            line-height: 16px;
            margin-left: 5px;
          }
        }
        .sd-inner-right {
          .sd-top-list {
            margin: 3px;
            // margin-bottom: 0px;
            margin-right: 0px;
            padding-top: 0px;

            .item {
              a {
                font-size: 400;
                color: white;
                font-size: 11px;
                text-decoration: none;
                padding: 0.2rem 8px;
                img {
                  height: 13px;
                }
              }
              img {
                height: 13px;
                padding: 0px 10px;
                // padding: 2px 10px 4px;
              }
            }
            .sd-spcl {
              img {
                padding-right: 0px;
              }
            }
          }
          .sd-bottom-list {
            margin: 3px;
            padding-left: 10px;
            display: flex;
            margin-right: 0px;
            margin-top: 0px;
            padding-top: 3px;

            list-style: none;
            align-items: center;

            .item {
              align-self: center;
              a {
                font-weight: 600;
                color: white;
                padding: 0.2rem 0.5rem;
                font-size: 11px;
                text-decoration: none;
              }
            }
            .border-r {
              border-right: 1px solid #c4681a;
              align-self: center;
            }
            .sd-first-button {
              align-self: center;
              height: 100%;
              font-size: 9px;
              display: flex;
              white-space: nowrap;

              .dropdown-menu {
                width: 100%;
                margin-top: -0.1vh;
                color: black !important;

                a {
                  font-size: 1vw;
                  padding: 0.5vw;
                  padding-left: 1.2vw;
                  color: black !important;
                  text-decoration: none;

                  &:hover > .side-hover {
                    display: block;
                  }

                  .side-hover {
                    box-shadow: 1px 1px 4px 0px;
                    display: none;
                    padding-top: 1vh;
                    padding-bottom: 1vh;

                    margin-left: 82%;
                    background-color: white;
                    position: absolute;
                    min-width: 100%;
                    margin-top: -17%;
                    div {
                      font-size: 1vw;
                      padding: 0.5vw;
                      padding-left: 2vw;
                      color: black !important;

                      a {
                        color: black !important;
                        padding-left: 0vw;
                        text-decoration: none;
                      }
                    }
                  }
                  .sd-left-hover {
                    margin-right: 0%;
                    margin-left: -105%;
                  }
                }
              }
              &:hover {
                background-color: #de6b08;
              }
              button {
                height: 80%;
                font-size: 11px;
                background-color: #f58220;
                color: white;
                border: none;
                box-shadow: none;
                white-space: nowrap;

                letter-spacing: 0.5px;
                font-weight: 600;
                margin: 0px;
                padding: 0.2rem 0.5rem;
                &:hover {
                  background-color: #de6b08;
                }
              }
              .sd-left {
                margin-left: -115px !important;
              }
            }
            .sd-dropdown {
              align-self: center;
              height: 100%;
              font-size: 9px;
              display: flex;
              .sd-dropbtn {
                align-self: center;
                height: 80%;
                font-size: 11px;
                background-color: #f58220;
                color: white;
                border: none;
                box-shadow: none;
                letter-spacing: 0.5px;
                font-weight: 600;
                margin: 0px;
                padding: 0.2rem 0.5rem;
                &:hover {
                  background-color: #de6b08;
                }
              }
              .sd-dropdown-content {
                margin-top: 30%;
                a {
                  font-size: 1vw;
                  padding: 0.5vw;
                  padding-left: 1.2vw;
                  color: black !important;
                  text-decoration: none;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 769px) {
  .sd-navbar-custom {
    height: 85px;
    .sd-top-nav {
      padding-left: 40px;
      padding-right: 40px;
      height: 25px;
      .sd-top-nav-left {
        font-size: 7px;

        div {
          font-size: 9px;
          padding-left: 5px;
          display: flex;
          img {
            height: 20px;
            width: 20px;

            padding: 4px;
          }
        }
      }
      .sd-top-nav-right {
        width: 50%;
        .sd-inner-div {
          font-size: 6px;

          img {
            margin-right: 6px;
            height: 8px;
            cursor: pointer;
          }
          a {
            text-decoration: none;
          }
          .sd-dropdown-signin {
            // width: 80px;
            img {
              margin-right: 3px;
            }
            button {
              white-space: nowrap;

              font-size: 7px;
            }
            .sd-dropdown-content-signin {
              min-width: 13vw;
              right: 0px;
              margin-top: -0.2vh;
              padding: 2 0px;
              a {
                font-size: 7px;
                padding: 0px 15px;
              }
            }
            .sd-dropbtn-signin {
              font-size: 6px;
              width: 60px;
              .spcl {
                padding-left: 0px;
                height: 8px;
              }
              img {
                height: 3px;
                margin-left: 4px;
              }
            }
          }
        }

        .dropdown {
          width: 18%;
          &:hover {
            background-color: #222268;
          }
          button {
            white-space: nowrap;

            font-size: 6px;
            img {
              height: 4px;
              margin-left: 6px;
              margin-top: 2px;
            }
          }
          .dropdown-menu {
            width: 100%;
            margin-left: -5%;
            a {
              font-size: 6px;
              padding: 10px;
              padding-left: 30px;
              color: black !important;
              &:hover {
              }
            }
          }
        }

        .sd-dropdown-language {
          .sd-dropbtn-language {
            font-size: 6px;
            img {
              height: 3px;
              margin-left: 4px;
            }
          }
          .sd-dropdown-content-language {
            width: 100px;
            a {
              font-size: 6px;
              padding: 6px;
              padding-left: 10px;
              color: black !important;
            }
          }
        }
        .sd-selected {
          background-color: #222268 !important;
        }
      }
    }
    .sd-bottom-nav {
      padding-left: 40px;
      padding-right: 40px;

      height: 60px;
      .slide-nav {
        display: none;
      }
      .sd-inner-navbar {
        .sd-inner-left {
          .logo {
            height: 50px;
          }
          a {
            margin-left: 5px;
            font-size: 11px;
            line-height: 13px;
          }
        }
        .sd-inner-right {
          .sd-top-list {
            margin: 0px;
            padding-bottom: 5px;
            padding-top: 0px;
            .item {
              a {
                font-size: 400;
                color: white;
                font-size: 10px;
                text-decoration: none;
                padding: 6px;
                img {
                  height: 10px;
                  padding: 0 8px;
                }
              }
              img {
                height: 10px;
                padding: 0.2px 7px;
                padding-right: 0px;
                // padding: 2px 10px 4px;
              }
            }

            .sd-spcl {
              padding-right: 0px;
            }
          }
          .sd-bottom-list {
            padding-left: 10px;
            display: flex;
            list-style: none;
            align-items: center;
            margin-top: 0px 0px 0px 3px;

            padding-top: 3px;
            .item {
              align-self: center;
              a {
                font-weight: 600;
                color: white;
                padding: 0.2rem 0.5rem;
                font-size: 8px;
                text-decoration: none;
              }
            }
            .border-r {
              border-right: 1px solid #c4681a;
              align-self: center;
            }
            .sd-first-button {
              align-self: center;
              height: 100%;
              font-size: 10px;
              display: flex;
              white-space: nowrap;

              .dropdown-menu {
                width: 100px !important;

                margin-top: -0.1vh;
                color: black !important;

                a {
                  font-size: 9px;
                  padding: 0px;
                  padding-left: 10px;
                  color: black !important;
                  text-decoration: none;

                  &:hover > .side-hover {
                    display: block;
                  }

                  .side-hover {
                    box-shadow: 1px 1px 4px 0px;
                    display: none;
                    padding-top: 1vh;
                    padding-bottom: 1 vh;

                    margin-left: 82%;
                    background-color: white;
                    position: absolute;
                    min-width: 100%;
                    margin-top: -17%;
                    div {
                      font-size: 1vw;
                      padding: 0.5vw;
                      padding-left: 2vw;
                      color: black !important;

                      a {
                        color: black !important;
                        padding-left: 0vw;
                        text-decoration: none;
                      }
                    }
                  }
                }
              }
              &:hover {
                background-color: #de6b08;
              }
              button {
                height: 80%;
                font-size: 10px;
                background-color: #f58220;
                color: white;
                border: none;
                box-shadow: none;
                font-weight: 600;
                margin: 0px;
                padding: 0.2rem 0.5rem;
                letter-spacing: 0px;
                &:hover {
                  background-color: #de6b08;
                }
              }
              .sd-left {
                margin-left: -120px !important;
              }
            }
            .sd-dropdown {
              align-self: center;
              height: 100%;
              font-size: 9px;
              display: flex;
              .sd-dropbtn {
                align-self: center;
                height: 80%;
                font-size: 8px;
                background-color: #f58220;
                color: white;
                border: none;
                box-shadow: none;
                letter-spacing: 0.5px;
                font-weight: 600;
                margin: 0px;
                padding: 0.2rem 0.5rem;
                &:hover {
                  background-color: #de6b08;
                }
              }
              .sd-dropdown-content {
                margin-top: 30%;
                min-width: 130px;
                a {
                  font-size: 8px;
                  padding: 5px;
                  padding-left: 10px;
                  color: black !important;
                  text-decoration: none;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) and (min-width: 100px) {
  .sd-navbar-custom {
    height: 60px;
    .sd-top-nav {
      display: none;
      padding: 0px;
      .sd-top-nav-right {
        width: 100%;
        .sd-inner-div {
          font-size: 7.5px;

          img {
            margin-right: 6px;
            height: 10px;
            cursor: pointer;
          }
          a {
            text-decoration: none;
          }
        }

        .dropdown {
          width: 18%;
          &:hover {
            background-color: #222268;
          }
          button {
            font-size: 7.5px;
            img {
              height: 6px;
              margin-left: 6px;
              margin-top: 2px;
            }
          }
          .dropdown-menu {
            width: 100%;
            margin-left: -5%;
            a {
              font-size: 11px;
              padding: 10px;
              padding-left: 30px;
              color: black !important;
            }
          }
        }
        .sd-selected {
          background-color: #222268 !important;
        }
      }
    }
    .sd-bottom-nav {
      height: 100%;
      padding: 0px;
      .slide-nav {
        display: block;
      }
      .sd-inner-navbar {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .sd-hamburger-icon {
          cursor: pointer;
          display: block;
          div {
            height: 2px;
          }
        }
        .none {
          display: none;
        }
        .block {
          display: block;
        }
        .sd-left-sider {
          width: 100vw;
          height: 100vh;
          position: absolute;
          background-color: white;
          left: 0;
          top: 0;
          z-index: 3443;
          // transform: translate3d(0, 0, 0);
          animation: slide-from-left 0.5s;
          // overflow: auto;
          box-sizing: border-box;
          // -webkit-overflow-scrolling: touch;
          .inner {
            height: 100%;
            text-align: center;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;

            // animation: slide-from-right 0.5s;

            .inner-nav-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #f58220;
              height: 9%;
              .left {
                display: flex;
                padding-left: 5px;
                a {
                  text-decoration: none;
                  color: white;
                  text-align: left;
                  font-size: 15px;
                }
                img {
                  padding: 8px;
                  height: 45px;
                  width: 45px;

                  align-self: center;
                }
              }
              .right {
                width: 52%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .dropdown {
                  // padding-left: 15px;
                  // padding-right: 15px;
                  // height: 80%;
                  background-color: rgb(255, 197, 125);
                  border-radius: 4px;

                  button {
                    font-size: 12px;
                    padding: 6px 13px;
                    border-radius: 6px;
                    font-weight: 600;
                    align-items: center;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    img {
                      padding-left: 8px;
                      height: 8px;
                      padding-right: 0px;
                      color: #181818;
                      filter: invert(1);
                    }
                  }
                  .dropdown-menu {
                    width: 100%;
                    margin-left: -5%;
                    a {
                      font-size: 13px;
                      padding: 10px;
                      padding-left: 30px;
                      color: #181818 !important;
                      font-weight: 500;
                    }
                  }
                }
                img {
                  height: 18px;
                  padding-left: 18px;
                  padding-right: 18px;
                }
                button {
                  height: 46%;
                  border: none;
                  border-left: 1px solid #bdbdbd;
                  display: flex;
                  font-size: 10px;
                  padding: 18px;
                  background-color: transparent;
                  span {
                    font-size: 50px;
                    color: white;
                    margin-bottom: 8px;
                    align-self: center;
                  }
                }
              }
            }
            .inner-bottom {
              height: 85%;
              &::-webkit-scrollbar {
                display: none;
              }
              .inner-bottom-t {
                padding: 8px 4px;
                div {
                  color: WHITE;
                  text-align: left;
                  padding: 10px;
                  margin-left: 20px;
                  font-weight: 600;
                  font-size: 14px;

                  img {
                    height: 18px;
                    margin-right: 10px;
                  }
                }
                background-color: #2f3193;
              }
              .inner-bottom-b {
                margin: 0px 20px;
                padding: 8px 4px;
                padding-bottom: 50px;

                div {
                  // background: antiquewhite;
                  padding: 10px;
                  color: #2f3193;
                  font-weight: 600;
                  text-align: left;
                  font-size: 16px;
                  width: 100%;
                  cursor: pointer;

                  img {
                    height: 18px;
                    margin-right: 5px;
                  }
                }
                .drop-one {
                  background: #fae1cb;
                  padding-left: 0px;
                  padding-right: 0px;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 20px;
                    }
                  }
                  .drop-11 {
                    background: #fef4eb;
                    width: 100%;
                    margin-left: -0px;
                    // padding-right: 20px;
                    div {
                      // margin-left: 35px;
                      // margin-right: 35px;
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-12 {
                    background: #fef4eb;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .block {
                    display: block;
                  }
                  .none {
                    display: none;
                  }
                }
                .drop-two {
                  background: #fef4eb;
                  div {
                    margin-left: 35px;
                    margin-right: 35px;
                    flex-wrap: wrap;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // background: rgb(252, 209, 157);
                    padding: 8px;
                    text-align: left;
                    font-weight: 400;
                    width: 100%;
                    line-height: 18px;
                    cursor: pointer;
                    img {
                      height: 18px;
                      margin-right: 25px;
                    }
                  }
                }
                .drop-three {
                  background: #fae1cb;
                  padding: 0px;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;
                    padding-right: -20px !important;
                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 20px;
                    }
                  }
                  .drop-8 {
                    background: #fef4eb;
                    width: 100%;
                    margin-left: -0px;
                    // padding-right: 20px;
                    div {
                      // margin-left: 35px;
                      // margin-right: 35px;
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-9 {
                    background: #fef4eb;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-10 {
                    background: #fef4eb;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .block {
                    display: block;
                  }
                  .none {
                    display: none;
                  }
                }
                .drop-four {
                  background: #fae1cb;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .drop-five {
                  background: #fae1cb;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .drop-six {
                  background: #fae1cb;
                  padding: 0px;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;
                    padding-right: -20px !important;
                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 20px;
                    }
                  }
                  .drop-13 {
                    background: #fef4eb;
                    width: 100%;
                    margin-left: -0px;
                    // padding-right: 20px;
                    div {
                      // margin-left: 35px;
                      // margin-right: 35px;
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-14 {
                    background: #fef4eb;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .drop-15 {
                    background: #fef4eb;
                    margin-left: -0px;

                    div {
                      flex-wrap: wrap;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      // background: rgb(252, 209, 157);
                      padding: 8px;
                      text-align: left;
                      font-weight: 400;
                      width: 100%;
                      line-height: 18px;
                      cursor: pointer;
                      img {
                        height: 18px;
                        margin-right: 25px;
                      }
                    }
                  }
                  .block {
                    display: block;
                  }
                  .none {
                    display: none;
                  }
                }
                .drop-seven {
                  background: #fae1cb;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .between {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
              }
            }
            .inner-footer {
              height: 6%;
              width: 100%;
              background-color: #efefef;
              position: absolute;
              display: flex;
              bottom: 0;
              justify-content: center;
              align-items: center;
              img {
                height: 25px;
                width: 25px;
                padding: 3px;
              }
            }
          }
          .inner-bottom {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }

          .close {
            background: rgb(243, 212, 172);
            padding: 10px;
            text-align: center;
            width: 100%;
            margin-bottom: 20px;
            font-size: 5vw;
            cursor: pointer;
          }
        }
        .sd-right-sider {
          width: 100vw;
          height: 100vh;
          position: absolute;
          background-color: white;
          left: 0;
          top: 0;
          z-index: 3443;
          animation: slide-from-right 0.5s;
          overflow: hidden;
          box-sizing: border-box;
          .inner {
            height: 100%;
            text-align: center;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            .inner-nav-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #f58220;
              height: 50px;
              .left {
                display: flex;
                padding-left: 5px;
                a {
                  text-decoration: none;
                  color: white;
                  text-align: left;
                  font-size: 15px;
                }
                img {
                  padding: 8px;
                  width: 50px;
                  height: 50px;

                  align-self: center;
                }
              }
              .right {
                width: 52%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .dropdown {
                  // padding-left: 15px;
                  // padding-right: 15px;
                  // height: 80%;
                  background-color: rgb(255, 197, 125);
                  border-radius: 4px;

                  button {
                    font-size: 12px;
                    padding: 6px 13px;
                    border-radius: 6px;
                    font-weight: 600;
                    align-items: center;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    img {
                      padding-left: 8px;
                      height: 8px;
                      padding-right: 0px;
                      color: #181818;
                      filter: invert(1);
                    }
                  }
                  .dropdown-menu {
                    width: 100%;
                    margin-left: -5%;
                    a {
                      font-size: 13px;
                      padding: 10px;
                      padding-left: 30px;
                      color: #181818 !important;
                      font-weight: 500;
                    }
                  }
                }
                img {
                  height: 18px;
                  padding-left: 18px;
                  padding-right: 18px;
                }
                button {
                  height: 46%;
                  border: none;
                  border-left: 1px solid grey;
                  display: flex;
                  font-size: 10px;
                  padding: 18px;
                  background-color: transparent;
                  span {
                    font-size: 50px;
                    color: white;
                    margin-bottom: 8px;
                    align-self: center;
                  }
                }
              }
            }
            .inner-bottom {
              height: 85%;
              &::-webkit-scrollbar {
                display: none;
              }
              .inner-bottom-t {
                padding: 8px 4px;
                text-align: left;
                color: white;
                img {
                  height: 100px;
                  padding: 20px;
                }
                div {
                  color: WHITE;
                  text-align: left;
                  padding: 10px;
                  margin-left: 20px;
                  font-weight: 600;
                  font-size: 14px;
                }
                background-color: #2f3193;
              }
              .inner-bottom-b {
                margin: 0px 20px;
                padding: 8px 4px;
                padding-bottom: 50px;

                div {
                  padding: 10px;
                  color: #2f3193;
                  font-weight: 600;
                  text-align: left;
                  font-size: 16px;
                  width: 100%;
                  cursor: pointer;

                  img {
                    height: 18px;
                    margin-right: 5px;
                  }
                }

                .drop-one {
                  background: #fae1cb;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .drop-right-one {
                  background: #fae1cb;
                  div {
                    margin-left: 15px;
                    margin-right: 15px;

                    font-weight: 500;
                    // background: bisque;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                      height: 18px;
                      margin-right: 11px;
                    }
                  }
                }
                .drop-two {
                  background: #fef4eb;
                  div {
                    margin-left: 35px;
                    margin-right: 35px;
                    flex-wrap: wrap;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // background: rgb(252, 209, 157);
                    padding: 8px;
                    text-align: left;
                    font-weight: 400;
                    width: 100%;
                    line-height: 18px;
                    cursor: pointer;
                    img {
                      height: 18px;
                      margin-right: 25px;
                    }
                  }
                }
                .between {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .sd-padding-none {
                    padding: 0px;
                    img {
                      height: 18px;
                      padding-right: 0px;
                    }
                  }
                }
              }
            }
            .inner-footer {
              height: 6%;
              width: 100%;
              background-color: #efefef;
              position: absolute;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 30px;
                padding: 3px;
              }
            }
          }
          .inner-bottom {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }

          .close {
            background: rgb(243, 212, 172);
            padding: 10px;
            text-align: center;
            width: 100%;
            margin-bottom: 20px;
            font-size: 5vw;
            cursor: pointer;
          }
        }
        .sd-user-slider {
          display: block;
          img {
            height: 25px;
            cursor: pointer;
          }
        }
        .sd-inner-left {
          .logo {
            height: 40px;
          }
          a {
            font-size: 15px;
            line-height: 15px;
            margin-left: 5px;
          }
        }
        .sd-inner-right {
          display: none;
        }
      }
    }
  }
}

@keyframes slide-from-left {
  0% {
    margin-left: -100vw;
  }
  100% {
    margin-left: 0vw;
  }
}

@keyframes slide-from-right {
  0% {
    margin-left: 100vw;
  }
  100% {
    margin-left: 0vw;
  }
}
